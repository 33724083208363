import makeStyles from "@mui/styles/makeStyles";

import { IMAGES } from "@APP/assets";
import CONFIG from "@APP/config";

// used by some environment's to show an alternative collapsed sidebar logo.
type LogoProps = {
  isFullSideBar: boolean;
};

const useStyles = makeStyles(() => ({
  logo: {
    height: 42,
    paddingRight: 12,
  },
}));

const Logo = (props: LogoProps) => {
  const classes = useStyles();
  return (
    <img
      className={classes.logo + " sidebarLogo"}
      src={IMAGES.APP_LOGO}
      alt={CONFIG.PRODUCT_NAME}
      id="sidebarLogo"
      style={{ width: "150px", height: "80px" }}
    />
  );
};

export default Logo;
