import { colors, ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#FAFAFA",
    paper: colors.common.white,
  },
  primary: {
    main: "#00205C",
  },
  secondary: {
    main: "#0F7DFF",
  },
  info: {
    main: "#00205C",
  },
  error: {
    main: "#8E0923",
  },
  action: {
    active: "#0F7DFF",
  },
  success: {
    main: "#D3F982",
  },
  text: {
    primary: "#00205C",
    secondary: colors.blueGrey[600],
  },
};

export default palette;
